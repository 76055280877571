<template>
  <nav class="sidebar-nav">
    <VuePerfectScrollbar class="scroll-area" :settings="psSettings" @ps-scroll-y="scrollHandle">
      <ul class="nav">
        <template v-for="(item, index) in this.$store.state.nav">
          <template v-if="isDealer(item.role)">
            <template v-if="item.title">
              <SidebarNavTitle :key="index" :name="item.name" :classes="item.class" :wrapper="item.wrapper" />
            </template>
            <template v-else-if="item.divider">
              <SidebarNavDivider :key="index" :classes="item.class" />
            </template>
            <template v-else-if="item.label">
              <SidebarNavLabel :key="index" :name="item.name" :url="item.url" :icon="item.icon" :label="item.label" :classes="item.class" />
            </template>
            <template v-else>
              <template v-if="item.children">
                <!-- First level dropdown -->
                <SidebarNavDropdown :key="index" :name="item.name" :url="item.url" :icon="item.icon">
                  <template v-for="(childL1, index1) in item.children">
                    <template v-if="childL1.children">
                      <!-- Second level dropdown -->
                      <SidebarNavDropdown :key="index1" :name="childL1.name" :url="childL1.url" :icon="childL1.icon">
                        <li :key="index2" class="nav-item" v-for="(childL2, index2) in childL1.children">
                          <SidebarNavLink :name="childL2.name" :url="childL2.url" :icon="childL2.icon" :badge="childL2.badge" :variant="item.variant" />
                        </li>
                      </SidebarNavDropdown>
                    </template>
                    <template v-else>
                      <template v-if="isDealer(childL1.role)">
                        <SidebarNavItem :key="index1" :classes="item.class">
                          <SidebarNavLink :name="childL1.name" :url="childL1.url" :icon="childL1.icon" :badge="childL1.badge" :variant="item.variant" />
                        </SidebarNavItem>
                      </template>
                    </template>
                  </template>
                </SidebarNavDropdown>
              </template>
              <template v-else>
                <SidebarNavItem :key="index" :classes="item.class">
                  <SidebarNavLink :name="item.name" :url="item.url" :icon="item.icon" :badge="item.badge" :variant="item.variant" />
                </SidebarNavItem>
              </template>
            </template>
          </template>
        </template>
      </ul>
      <slot></slot>
    </VuePerfectScrollbar>
  </nav>
</template>

<script>
import SidebarNavDivider from './SidebarNavDivider'
import SidebarNavDropdown from './SidebarNavDropdown'
import SidebarNavLink from './SidebarNavLink'
import SidebarNavTitle from './SidebarNavTitle'
import SidebarNavItem from './SidebarNavItem'
import SidebarNavLabel from './SidebarNavLabel'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
// import navControl from './navCtrl.json'
import navItemCtrl from './navItemCtrl'

export default {
  name: 'SidebarNav',
  props: {
    // navItems: {
    //   type: Array,
    //   required: true,
    //   default: () => []
    // }
  },
  components: {
    SidebarNavDivider,
    SidebarNavDropdown,
    SidebarNavLink,
    SidebarNavTitle,
    SidebarNavItem,
    SidebarNavLabel,
    VuePerfectScrollbar
  },
  data() {
    return {
      role: window.localStorage.getItem('roleID'),
      psSettings: {
        maxScrollbarLength: 200,
        minScrollbarLength: 40,
        suppressScrollX: true,
        wheelPropagation: false,
        interceptRailY: styles => ({ ...styles, height: 0 })
      },
      navItems: this.$store.state.nav
    }
  },
  methods: {
    scrollHandle(evt) {
      // console.log(evt)
    },
    isDealer(role) {
      if (this.role === 'admin') {
        if (navItemCtrl.admin.indexOf(role) !== -1) {
          return true
        }
      } else if (this.role === 'dealer') {
        if (navItemCtrl.dealer.indexOf(role) !== -1) {
          return true
        }
      } else if (this.role === 'owner') {
        if (navItemCtrl.owner.indexOf(role) !== -1) {
          return true
        }
      } else if (this.role === 'callcenter') {
        if (navItemCtrl.callcenter.indexOf(role) !== -1) {
          return true
        }
      }
    }
  }
}
</script>

<style scoped lang="css">
.scroll-area {
  position: absolute;
  height: 100%;
  margin: auto;
}
</style>
